.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #333;
  animation: bounce 0.6s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.custom-font, .book-background, .card {
  color: rgb(248, 183, 20);
  font-family: serif;
  font-style: italic;
   -webkit-text-stroke: .1px black;
}

.book, .book-background {
  background-color: #97572b;
  background-image: 
    radial-gradient(circle, #5b3318 8%, transparent 9%),
    radial-gradient(circle, #321603 12%, transparent 13%),
    radial-gradient(circle, #c57c4b 6%, transparent 7%);
  background-size: 45px 45px, 49px 49px, 34px 34px;
  background-position: 20px 20px, 70px 80px, 40px 50px;
}

.card {
  opacity: 0; 
  transform: translateY(50px); 
  animation: floatIn 1s forwards ease-in-out;
}


.card:nth-child(1) {
  animation-delay: 0s; 
}

.card:nth-child(2) {
  animation-delay: 0.3s; 
}

.card:nth-child(3) {
  animation-delay: 0.6s;
}


@keyframes floatIn {
  0% {
      opacity: 0;
      transform: translateY(50px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.card-container {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 100px; 
  padding: 20px;
}

.card {
  width: 100%;
  flex: 1 1 15%;
  max-width: 21%; 
  min-width: 150px; 
  margin: 10px;
}

.book-background {
  padding-right: 15%;
}

.sidebar {
  width: 16.5%;
  height: 100vh;
  position: fixed;
  top: 80px; 
  background-color: #753737;
  z-index: 999;
}


.left-sidebar {
  left: 0;
  background-image: linear-gradient(
    to right,
    #000000 5px,
    transparent 5px,
    transparent 4px,
    #822e0d 8px,
    transparent 4px,
    transparent 7px,
    #8e6f50 7px
  );
  background-size: 15px 100px;
}

.right-sidebar {
  right: 0;
  background-image: linear-gradient(
    to right,
    #000000 5px,
    transparent 5px,
    transparent 4px,
    #822e0d 8px,
    transparent 4px,
    transparent 7px,
    #8e6f50 7px
  );
  background-size: 15px 100px;
}

.screen {
  background-color: rgb(212, 212, 160);
  background-image: 
    radial-gradient(circle, #cac7ba 12%, transparent 13%);
  background-size: 45px 45px, 49px 49px, 34px 34px;
  background-position: 20px 20px, 70px 80px, 40px 50px;
}

.footer-font {
  color: #97572b;
}